import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../base/base.component';
import { AuthService, PatientService } from 'src/app/services';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { MatSelect } from '@angular/material/select';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import jspdf from 'jspdf';
import domtoimage from 'dom-to-image';
import { Router } from '@angular/router';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM',
  },
  display: {
    dateInput: 'DD MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD MMM',
    monthYearA11yLabel: 'MMM YYYY',
  },
};


@Component({
  selector: 'app-data-box-kinesis-configuration',
  templateUrl: './data-box-kinesis-configuration.component.html',
  styleUrls: ['./data-box-kinesis-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },
  ],
})

export class DataBoxKinesisConfigurationComponent extends BaseComponent implements OnInit {
  isThreshold: any;
  isDataBox: any;
  isRefreshFrequency: any;
  isReportGeneration: any;
  leftMidRisk: any;
  leftHighRisk: any;
  rightMidRisk: any;
  rightHighRisk: any;
  proneMidRisk: any;
  proneHighRisk: any;
  supineMidRisk: any;
  supineHighRisk: any;
  patientNumber: any;
  bufferTime: any;
  threshold: any;
  reportEmailList: any;
  reportSMSList: any;
  samePoseEmail: any;
  samePoseSMS: any;
  noDataEmail: any;
  noDataSMS: any;
  weeklyReportEmail: any;
  weeklyReportSMS: any;
  thresholdPatient:any;
  addThresholdValues: FormGroup;
  currentPage = 1;
  itemsPerPage = 10;
  data: any[] = [];
  selectedPatient: any;
  patientConfigValues: any = [];
  patientData: any[] = [];
  showEmailLists: { [key: string]: boolean } = {};
  showPatientSelection: boolean = true;
  HubID: any = '';
  rTime: number = 0;
  selectTimeZone: any;
  selectedTZone: any;
  timeZone: any = [];
  searchKeyword: any;
  dataLoader: boolean = true;
  totalItems: number;
  loading = false;
  alertFrequency: any;
  isValueSelected: boolean = false;
  dateTime_start: any;
  dateTime_end: any;
  pdf_data: any = [];
  chartData: any;
  @Input() doChartData: any = [];
  @Input() timelineId;
  timelineGraphData: any;
  patient_number: any;
  graphId: any;
  peferredPositon: any;
  longestStay: any;
  percentageData: any[] = [];
  dateRange: any;
  timeGraphId: any;
  apiCallInProgress: boolean = false;
  isHubidApi: boolean = false;
  //futureDate: any = new Date();
  donutDays: any;
  patientTimeZone: any;
  preferedPatientName: any;

  @ViewChild(MatSelect) select: MatSelect;

  constructor(private formBuilder: FormBuilder,
    private patientService: PatientService,
    public auth: AuthService,
    public datepipe: DatePipe,
    private toaster: ToastrService,
    private router: Router,) {
    super();
    this.thresholdPatient = this.formBuilder.group({
      seletPatient:[''],
    });
    this.addThresholdValues = this.formBuilder.group({
      leftMidRisk: ['', Validators.required],
      leftHighRisk: ['', Validators.required],
      rightMidRisk: ['', Validators.required],
      rightHighRisk: ['', Validators.required],
      proneMidRisk: ['', Validators.required],
      proneHighRisk: ['', Validators.required],
      supineMidRisk: ['', Validators.required],
      supineHighRisk: ['', Validators.required],
      patientNumber: ['', Validators.required],
      bufferTime: ['', Validators.required],
      threshold: ['', Validators.required],
      reportEmailList: ['', Validators.required],
      reportSMSList: ['', Validators.required],
      samePoseEmail: ['', Validators.required],
      samePoseSMS: ['', Validators.required],
      noDataEmail: ['', Validators.required],
      noDataSMS: ['', Validators.required],
      weeklyReportEmail: ['', Validators.required],
      weeklyReportSMS: ['', Validators.required],
      selectTimeZone: ['', Validators.required],
      HubID: [null, Validators.required],
      rTime: [null, Validators.required],
      alertFrequency: [null, Validators.required],
      searchKeyword: [''],
    });
  }
  ngOnInit(): void {
    window.addEventListener('storage', (e) => {
      if(e.key === 'base_org') {
        if(!this.router.url.includes(e.newValue)){
          window.location.href = '/admin';
        }
      }
    })
    this.AlllistPatients();
    this.Threshold();
    this.timeZone = moment.tz.names();
    this.timeZoneChanged(this.timeZone);
  }
  patientname = [
    { name: "Ward {WARD} Bed {BED}" },
    { name: "{BED} in room {WARD}" },
    { name: "Room {WARD} Bed {BED}" }
  ]
  Threshold(): void {
    this.isThreshold = true;
    this.isDataBox = false;
    this.isRefreshFrequency = false;
    this.isReportGeneration = false;
    this.dateTime_start = '';
    this.dateTime_end = '';
    this.dataLoader = false;
  }
  dataBox(): void {
    this.isThreshold = false;
    this.isDataBox = true;
    this.isRefreshFrequency = false;
    this.isReportGeneration = false;
    this.HubID = "";
    this.dateTime_start = '';
    this.dateTime_end = '';
  }
  refreshFrequencyData(): void {
    this.isThreshold = false;
    this.isDataBox = false;
    this.isRefreshFrequency = true;
    this.isReportGeneration = false;
    this.rTime = 0;
    this.dateTime_start = '';
    this.dateTime_end = '';
  }
  reportGeneration(): void {
    this.isThreshold = false;
    this.isDataBox = false;
    this.isRefreshFrequency = false;
    this.isReportGeneration = true;
    this.closePatient();
    this.dataLoader = true;
  }
  selectPatient(selectedPatient: any): void {
    this.isValueSelected = true;
    this.thresholdPatient.get('seletPatient').setValue(selectedPatient);
    this.selectedPatient = selectedPatient;
  }

  AlllistPatients(): void {
    if (this.auth.isTokenValid()) {
      let params = new HttpParams()
        .set('pageNumber', this.currentPage.toString())
        .set('pageSize', this.itemsPerPage.toString());
      this.patientService.listOfPatients(params).subscribe((res) => {
        this.totalItems = res.totalPages;
        this.data = res.data.map((item) => {
          return item.patient_number;
        });
        this.patientData = this.data;
      });
    } else {
      this.auth.refreshToken();
    }
  }
  closePatient():any {
    this.thresholdPatient.get('seletPatient').setValue('');
    this.isValueSelected = false;
  }
  onSelectDropdownOpened(): void {
    if (this.auth.isTokenValid()) {
      this.select.panel.nativeElement.addEventListener('scroll', () => {
        if (this.shouldLoadNextPage()) {
          this.loadNextPage();
        }
      });
      window.addEventListener('resize', () => { // Add resize event listener
        if (this.shouldLoadNextPage()) {
          this.loadNextPage();
        }
      });

      this.checkPagination(); // Check pagination initially
    } else {
      this.auth.refreshToken();
    }
  }
  checkPagination(): void {
    if (this.shouldLoadNextPage()) {
      this.loadNextPage();
    }
  }

  shouldLoadNextPage(): boolean {
    if (!this.loading && this.currentPage < this.totalItems) {
      const panel = this.select.panel.nativeElement;
      return panel.scrollHeight - panel.scrollTop <= panel.clientHeight + 1;
    }
    return false;
  }

  loadNextPage(): void {
    if (this.auth.isTokenValid()) {
      this.loading = true;
      this.currentPage++;
      let params = new HttpParams()
        .set('pageNumber', this.currentPage.toString())
        .set('pageSize', this.itemsPerPage.toString());
      this.patientService.listOfPatients(params).subscribe((res) => {
        this.patientData = this.patientData.concat(res.data.map((item) => item.patient_number));
        this.loading = false;
      });
    } else {
      this.auth.refreshToken();
    }
  }

  timeZoneChanged(timeZone: string): void {
    this.selectedTZone = timeZone;
  }
  selectPatientName(selectedPatientName: any) {
    this.preferedPatientName = selectedPatientName;
  }
  filterTimeZones(value: any) {
    this.searchKeyword = value;
    if (!value || value.trim() === '') {
      this.selectedTZone = this.timeZone;
    } else {
      this.selectedTZone = this.selectedTZone.filter(option =>
        option.toLowerCase().includes(value.toLowerCase())
      );
    }
  }
  onInputChange() {
    if (this.searchKeyword || this.selectedTZone.length >= 0) {
      //this.selectedTZone = this.selectedTZone;
      this.filterTimeZonesByName(this.searchKeyword);
    }
  }
  filterTimeZonesByName(value: string) {
    this.searchKeyword = value;
    if (!value || value.trim() === '') {
      this.selectedTZone = this.timeZone;
    } else {
      this.selectedTZone = this.timeZone.filter(option =>
        option.toLowerCase().includes(value.toLowerCase())
      );
    }
  }
  SelectTimeZone(selectedtimeZone: any) {
    this.selectTimeZone = selectedtimeZone.value;
    this.searchKeyword = '';
  }
  onSelectMenuClosed(): void {
    this.isValueSelected = true;
    this.searchKeyword = '';
    this.timeZoneChanged(this.timeZone);
  }
  handleInputChange(fieldName: string, newValue: any) {
    switch (fieldName) {
      case 'bufferTime':
        this.bufferTime = newValue;
        break;
      case 'threshold':
        this.threshold = newValue;
        break;
      case 'leftMidRisk':
        this.leftMidRisk = newValue;
        break;
      case 'leftHighRisk':
        this.leftHighRisk = newValue;
        break;
      case 'rightMidRisk':
        this.rightMidRisk = newValue;
        break;
      case 'rightHighRisk':
        this.rightHighRisk = newValue;
        break;
      case 'proneMidRisk':
        this.proneMidRisk = newValue;
        break;
      case 'proneHighRisk':
        this.proneHighRisk = newValue;
        break;
      case 'supineMidRisk':
        this.supineMidRisk = newValue;
        break;
      case 'supineHighRisk':
        this.supineHighRisk = newValue;
        break;
      case 'alertFrequency':
        this.alertFrequency = newValue;
        break;
      case 'selectTimeZone':
        this.selectTimeZone = newValue;
        break;
      case '24h_report_email_list':
        let dailyEmailReport = newValue.trim();
        if (dailyEmailReport !== '') {
          this.patientConfigValues[0]['24h_report_email_list'] = [
            ...(this.patientConfigValues[0]['24h_report_email_list'] || []).filter(email => email.trim() !== ''),
            dailyEmailReport
          ];
        }
        break;
      case '24h_report_sms_list':
        let dailySMSReport = newValue.trim();
        if (dailySMSReport !== '') {
          this.patientConfigValues[0]['24h_report_sms_list'] = [
            ...(this.patientConfigValues[0]['24h_report_sms_list'] || []).filter(email => email.trim() !== ''),
            dailySMSReport
          ];
        }
        break;
      case 'no_data_alert_email_list':
        let noDataEmailReport = newValue.trim();
        if (noDataEmailReport !== '') {
          this.patientConfigValues[0]['no_data_alert_email_list'] = [
            ...(this.patientConfigValues[0]['no_data_alert_email_list'] || []).filter(email => email.trim() !== ''),
            noDataEmailReport
          ];
        }
        break;
      case 'no_data_alert_sms_list':
        let noDataSMSReport = newValue.trim();
        if (noDataSMSReport !== '') {
          this.patientConfigValues[0]['no_data_alert_sms_list'] = [
            ...(this.patientConfigValues[0]['no_data_alert_sms_list'] || []).filter(email => email.trim() !== ''),
            noDataSMSReport
          ];
        }
        break;
      case 'same_pose_alert_email_list':
        let sameEmailReport = newValue.trim();
        if (sameEmailReport !== '') {
          this.patientConfigValues[0]['same_pose_alert_email_list'] = [
            ...(this.patientConfigValues[0]['same_pose_alert_email_list'] || []).filter(email => email.trim() !== ''),
            sameEmailReport
          ];
        }
        break;
      case 'same_pose_alert_sms_list':
        let sameSMSReport = newValue.trim();
        if (sameSMSReport !== '') {
          this.patientConfigValues[0]['same_pose_alert_sms_list'] = [
            ...(this.patientConfigValues[0]['same_pose_alert_sms_list'] || []).filter(email => email.trim() !== ''),
            sameSMSReport
          ];
        }
        break;
      case 'weekly_report_email_list':
        let weeklyEmail = newValue.trim();
        if (weeklyEmail !== '') {
          this.patientConfigValues[0]['weekly_report_email_list'] = [
            ...(this.patientConfigValues[0]['weekly_report_email_list'] || []).filter(email => email.trim() !== ''),
            weeklyEmail
          ];
        }
        break;
      case 'weekly_report_sms_list':
        let dailyreport = newValue.trim();
        if (dailyreport !== '') {
          this.patientConfigValues[0]['weekly_report_sms_list'] = [
            ...(this.patientConfigValues[0]['weekly_report_sms_list'] || []).filter(email => email.trim() !== ''),
            dailyreport
          ];
        }
        break;
    }
  }

  onSelect(selectedEmail: any): void {
    this.isValueSelected = true;
    this.thresholdPatient.get('seletPatient').setValue(selectedEmail);
    this.selectedPatient = selectedEmail;
  }
  patientConfigValue(): void {
    if (this.auth.isTokenValid()) {
      this.showPatientSelection = false;
      this.dataLoader = true;
      this.patientService.patientConfig(this.selectedPatient).subscribe((res) => {
        this.patientConfigValues = [];
        this.patientConfigValues.push(res);
        this.dataLoader = false;
      });
    } else {
      this.auth.refreshToken();
    }
  }
  toggleEmailList(key: string) {
    this.showEmailLists[key] = !this.showEmailLists[key];
    if (!this.patientConfigValues[0][key]) {
      this.patientConfigValues[0][key] = [];
    }
  }

  addEmailField(key: string) {
    if (this.auth.isTokenValid()) {
      let newEmail = "";
      this.patientConfigValues[0][key].push(newEmail);
    } else {
      this.auth.refreshToken();
    }
  }


  removeEmailField(key: string, index: number) {
    if (this.auth.isTokenValid()) {
      this.patientConfigValues[0][key].splice(index, 1);
    } else {
      this.auth.refreshToken();
    }
  }
  updateData(): void {
    if (this.auth.isTokenValid()) {
      let data: any = {
        "patient_number": this.patientConfigValues[0].patient_number,
        "Buffer Time": this.patientConfigValues[0].buffer_time,
        "Threshold": this.patientConfigValues[0].threshold,
        "Left Mid Risk Threshold": this.patientConfigValues[0].left_mid_risk_threshold,
        "Left High Risk Threshold": this.patientConfigValues[0].left_high_risk_threshold,
        "Right Mid Risk Threshold": this.patientConfigValues[0].right_mid_risk_threshold,
        "Right High Risk Threshold": this.patientConfigValues[0].right_high_risk_threshold,
        "Prone Mid Risk Threshold": this.patientConfigValues[0].prone_mid_risk_threshold,
        "Prone High Risk Threshold": this.patientConfigValues[0].prone_high_risk_threshold,
        "Supine Mid Risk Threshold": this.patientConfigValues[0].supine_mid_risk_threshold,
        "Supine High Risk Threshold": this.patientConfigValues[0].supine_high_risk_threshold,
        "Alert Frequency": this.patientConfigValues[0].alert_frequency,
        "prefered_patientname": this.preferedPatientName,
        "Time Zone": this.selectTimeZone,
        "24H Report Email List": this.patientConfigValues[0]['24h_report_email_list'],
        "24H Report SMS List": this.patientConfigValues[0]['24h_report_sms_list'],
        "No Data Alert Email List": this.patientConfigValues[0]['no_data_alert_email_list'],
        "No Data Alert SMS List": this.patientConfigValues[0]['no_data_alert_sms_list'],
        "Same Pose Alert Email List": this.patientConfigValues[0]['same_pose_alert_email_list'],
        "Same Pose Alert SMS List": this.patientConfigValues[0]['same_pose_alert_sms_list'],
        "Weekly Report Email List": this.patientConfigValues[0]['weekly_report_email_list'],
        "Weekly Report SMS List": this.patientConfigValues[0]['weekly_report_sms_list']
      };
      this.dataLoader = true;
      this.patientService.updatePatientConfig(this.selectedPatient, data).subscribe((res) => {
        this.patientConfigValues = [];
        this.patientConfigValues.push(res);
        this.toaster.success('Data Updated Successfully');
        this.dataLoader = false;
      })
    } else {
      this.auth.refreshToken();
    }
  }
  clearForm() {
    if (this.auth.isTokenValid()) {
      this.addThresholdValues.reset();
      this.showPatientSelection = true;
    } else {
      this.auth.refreshToken();
    }
  }
  dataBoxHub(): void {
    if (this.isHubidApi) {
      return;
    }
    if (this.auth.isTokenValid()) {
      let data = {
        "hub_id": this.HubID
      }
      this.isHubidApi = true;
      this.patientService.dataBoxRegister(data).subscribe((res) => {
        if (res.status == "Success") {
          this.toaster.success('Databox Successfully Updated');
        } else if (res.status == 'error') {
          this.toaster.error('Databox with the same hub_id already exists');
        }
        this.isHubidApi = false;
      })
    } else {
      this.auth.refreshToken();
    }
  }
  clearHubDetail(): void {
    if (this.auth.isTokenValid()) {
      this.HubID = '';
    } else {
      this.auth.refreshToken();
    }
  }
  refreshFreqData(): void {
    if (this.auth.isTokenValid()) {
      if (this.rTime >= 1) {
        this.toaster.success('Automatic Refresh timer is Updated Successfully');
        localStorage.setItem('autoRefresh', JSON.stringify(this.rTime));
      } else {
        this.toaster.error('Please enter a positive value for the refresh timer.');
      }
    }
  }
  clearRefreshDetail(): void {
    if (this.auth.isTokenValid()) {
      this.rTime = 0;
    } else {
      this.auth.refreshToken();
    }
  }
  validationReport(): void {
    if (this.selectedPatient == undefined || this.selectedPatient == "") {
      this.toaster.error('Please select Patient Number', '');
    }
    else if (this.dateTime_start == undefined || this.dateTime_start == "") {
      this.toaster.error('Please enter Start Date', '');
    }
    else if (this.dateTime_end == undefined || this.dateTime_end == "") {
      this.toaster.error('Please enter End Date', '');
    } else {
      const startTime = new Date(this.dateTime_start);
      const endTime = new Date(this.dateTime_end);
      var diff = endTime.getTime() - startTime.getTime();
      var days = Math.ceil(diff / (60 * 60 * 24 * 1000));
      var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
      var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
      var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
      if (days > 7) {
        this.toaster.error('Error: please select a maximum period of 1-week')
      }
      else if (startTime == endTime) {
        this.toaster.error('Start and End Date not be equal');
      }
      else if (startTime.getTime() > endTime.getTime()) {
        this.toaster.error("End date should be greater than Start date");
      }
      else {
        this.reportData();
      }
    }

  }
  reportData(): void {
    let startDate: any = this.datepipe.transform(new Date(this.dateTime_start), 'yyyy-MM-dd HH:mm:ss');
    let endDate: any = this.datepipe.transform(new Date(this.dateTime_end), 'yyyy-MM-dd HH:mm:ss');
    this.dataLoader = false;
    if (this.auth.isTokenValid()) {
      this.patientService.patientReport(startDate, endDate, this.selectedPatient).subscribe((res) => {
        if (Array.isArray(res) && res.length > 0) {
          const firstResponse = res[0];
          if (typeof firstResponse === 'object' && firstResponse.hasOwnProperty('Error')) {
            this.toaster.error(firstResponse.Error);
          }
        }
        this.timelineGraphData = res[this.selectedPatient].patient_data;
        if (this.timelineGraphData) {
          this.patient_number = this.selectedPatient;
          this.graphId = res[this.selectedPatient].patient_data.PreferedPatientName;
          this.peferredPositon = res[this.selectedPatient].patient_data.message1;
          this.extractDateRange();
          this.longestStay = res[this.selectedPatient].patient_data.message2;
          this.chartData = [res[this.selectedPatient].posture_percentages];
          this.percentageData = this.chartData[0];
          this.donutDays = this.timelineGraphData.days;
          let dateExtract = this.timelineGraphData.Timezone;
          const parts = dateExtract.split(' ');
          this.patientTimeZone = parts[parts.length - 1];
          this.formatDateRange();
        }
        this.toaster.success('Request received. Report will begin downloading shortly.', '');
        setTimeout(() => {
          this.convertToPDF();
        }, 100);
        this.dataLoader = true;
      }, (error) => {
        if (error.status === 500) {
          this.toaster.error(error.error.Error);
        }
      })
    } else {
      this.auth.refreshToken();
    }
  }
  extractDateRange() {
    const regex = /From (\d{1,2} \w+ \d{4} \d{2}:\d{2}:\d{2}) to (\d{1,2} \w+ \d{4} \d{2}:\d{2}:\d{2})/;
    const matches = this.peferredPositon.match(regex);
    if (matches && matches.length === 3) {
      this.dateRange = `${matches[1]} to ${matches[2]}`;
    }
  }
  formatDateRange() {
    const regex = /(\d{1,2} \w+ \d{4})/g;
    const matches = this.peferredPositon.match(regex);
    if (matches && matches.length >= 2) {
      const startDate = new Date(matches[0]);
      const endDate = new Date(matches[1]);

      const timezoneOffset = startDate.getTimezoneOffset();
      startDate.setMinutes(startDate.getMinutes() - timezoneOffset);
      endDate.setMinutes(endDate.getMinutes() - timezoneOffset);

      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];

      this.timeGraphId = `${this.graphId} : ${this.patientTimeZone}  ${formattedStartDate} to ${formattedEndDate}`;
    }
  }

  convertToPDF(): any {
    const node = document.getElementById('contentToConvert');
    let img;
    let filename;
    let newImage;
    domtoimage
      .toPng(node, { bgcolor: '#fff', transform: 'scale(2,2)' })
      .then((dataUrl) => {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = () => {
          const pdfWidth = img.width;
          const pdfHeight = img.height;
          let doc;

          if (pdfWidth > pdfHeight) {
            doc = new jspdf('l', 'px', [pdfWidth, pdfHeight]);
          } else {
            doc = new jspdf('p', 'px', [pdfWidth, pdfHeight]);
          }

          const width = doc.internal.pageSize.getWidth();
          const height = doc.internal.pageSize.getHeight();
          doc.addImage(newImage, 'PNG', 20, 20, width, height);
          filename = this.graphId + ' ' + '.pdf';
          doc.save(filename);
        };
      })
      .catch((error) => {
        // Error Handling
      });
  }
  clearReportData(): void {
    this.thresholdPatient.get('seletPatient').setValue('');
    this.isValueSelected = false;
    this.dateTime_start = '';
    this.dateTime_end = '';
  }

}

import { Component, Input, OnInit, OnChanges, HostBinding, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '../base/base.component';

import * as d3 from 'd3';
import * as d3Time from 'd3-time';
import * as d3TimeFormat from 'd3-time-format';
import * as d3Scale from 'd3-scale';
import * as d3Axis from 'd3-axis';
import * as d3Selection from 'd3-selection';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { PatientService } from 'src/app/services';
import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent extends BaseComponent implements OnInit {
  @Input() dataToDisplay: any = [];
  containerSize: any;
  xScale: any;
  xAxis: any;
  yScale: any;
  yAxis: any;
  svg: any = null;
  scaleFactor = 1;
  margin: { left: number; right: number; top: number; bottom: number; };
  start: any;
  end: any;
  width: any;
  height: any;
  @Input() timelineId: any;
  poseBlock: any = [];
  continuesBlock: any;
  timelinedata: any = [];
  itemHeight;
  itemMargin;
  myArray: any = []
  selectedHour: any;
  @Input() selected: boolean;
  @Input() displayTimeline: boolean;
  @Input() graphId: any;
  constructor
    (
      private router: Router,
      public dialog: MatDialog,
      public datepipe: DatePipe,
      private parentDilogRef: MatDialogRef<TimelineComponent>,
    ) {
    super();
    this.margin = { left: 30, right: 30, top: 20, bottom: 30 };
    this.width = 400 - this.margin.left - this.margin.right;
    this.height = 200 - this.margin.top - this.margin.bottom;
  }
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.poseBlock = this.dataToDisplay?.PoseBlock || []
    this.continuesBlock = this.dataToDisplay?.ContinueBlock || []

    if (this.dataToDisplay && Object.keys(this.dataToDisplay).length > 0) {
      this.svg = null;
      d3.selectAll(`#${this.timelineId}`).select("svg").remove();
      this.myArray = Object.keys(this.dataToDisplay).map(key => ({ [key]: [this.dataToDisplay[key]] }));
      this.drawTimelines();
    }
  }
  drawTimelines(): void {
    let now = new Date(this.dataToDisplay.start_time);
    this.start = now.getTime();

    // Set the end time to today 12 PM
    let today = new Date(this.dataToDisplay.end_time);
    this.end = today.getTime();


    this.svg = this.appendContainer();
    this.containerSize = (this.svg.node() as any).getBoundingClientRect();
    this.scaleFactor = (1 / (this.end - this.start)) * (this.containerSize.width - this.margin.left - this.margin.right);
    var graph = this.appendGraph(this.svg)

    this.xScale = d3Scale
      .scaleTime()
      .domain([this.start, this.end])
      .range([this.margin.left, this.containerSize.width - this.margin.right])

    this.xAxis = d3Axis
      .axisBottom()
      .scale(this.xScale)
      .ticks(d3Time.timeMinute.every(30))
      .tickFormat(d3TimeFormat.timeFormat('%H:%M'))
      .tickSize(3)
      .tickPadding(5);

    this.yScale = d3Scale
      .scaleLinear()
      .domain([now, today])
      .range([this.margin.top, this.containerSize.height - this.margin.bottom]);

    this.yAxis = d3Axis.axisLeft().scale(this.yScale)

    this.svg
      .append('line') //horizontal
      .attr('x1', 15)
      .attr('y1', 45)
      .attr('x2', '96.8%')
      .attr('y2', 45)
      .style('stroke', 'gray')
      .style('stroke-width', 0.5);

    // Display yesterday's date on the left
    this.svg
      .append('text')
      .attr('x', 5)
      .attr('y', 50)
      .attr('dy', -25)
      .attr('text-anchor', 'start')
      .attr('transform', 'rotate(-90, ' + this.margin.left + ', ' + 45 + ')')
      .text(d3TimeFormat.timeFormat('%d-%m-%Y')(now))
      .style('font-size', 9);

    // Display today's date on the right
    this.svg
      .append('text')
      .attr('x', '99%')
      .attr('y', 50)
      .attr('dy', 0)
      .attr('text-anchor', 'end')
      .attr('transform', 'rotate(-90, ' + (this.containerSize.width - this.margin.right) + ', ' + 45 + ')')
      .text(d3TimeFormat.timeFormat('%d-%m-%Y')(today))
      .style('font-size', 9);

    // const timestamps = d3.timeMinute.range(this.start, this.end, 15);
    const verticalLines = d3.timeMinute.every(15).range(this.start, this.end);
    verticalLines.forEach((timestamp) => {
      if (timestamp >= this.start && timestamp <= this.end) {
        const xPosition = this.xScale(timestamp);

        this.svg
          .append('line') // Vertical lines
          .attr('x1', xPosition - 10)
          .attr('y1', this.margin.top)
          .attr('x2', xPosition - 10)
          .attr('y2', 70)
          .style('stroke', 'grey')
          .style('stroke-width', 0.5);
      }
    });


    this.svg.append('rect')
      .attr('x', 20)
      .attr('y', this.margin.top)
      .attr('width', this.containerSize.width - 60)
      .attr('height', 50)
      .style('fill', 'none')
      .style('stroke', 'lightgrey')
      .style('stroke-width', 1);

    this.svg.append('g')
      .attr("transform", 'translate(' + -10 + ',' + 70 + ')')
      .call(this.xAxis)
      .selectAll('text')
      .style('text-anchor', 'middle')
      .attr('dy', '-4px')
      .attr('transform', 'rotate(-90)')
      .attr('dx', -20)
      .attr('font-size', 9)

    this.svg.selectAll('.tick text')
    
    this.bellIconImage();
    this.drawPoseBlockGraph(graph);
    this.drawContinueBlockGraph(graph);// Draw ContinueBlock bars at the bottom
  }
  onResized(event: ResizedEvent): void {
    if (this.dataToDisplay && Object.keys(this.dataToDisplay).length > 0) {
      this.svg = null;
      d3.select(`#${this.timelineId}`).select('svg').remove();
      this.drawTimelines();
    }
  }
  appendContainer(): any {
    if (!this.svg) {
      return d3
        .select(`#${this.timelineId}`)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '103px')
        .style('margin-left', 35)
    } else {
      return this.svg;
    }
  }
  appendGraph(svg): any {
    return svg
      .append('g')
      .attr('class', 'container')
      .attr('height', this.containerSize.height)
      .datum(this.myArray);
  }

  bellIconImage(): void {
    const bellIconPositions = this.dataToDisplay.alert_sent_times.map((alertTime) => {
      const date = new Date(alertTime);
      return this.xScale(date);
    });

    // Append bell icons
    bellIconPositions.forEach((xPosition) => {
      this.svg
        .append('image')
        .attr('xlink:href', 'assets/images/pngwing.png')  // Replace with your bell icon path
        .attr('width', 10)
        .attr('height', 10)
        .attr('x', xPosition - 15)  // Adjust based on icon size
        .attr('y', 20);  // Adjust based on icon size and position above poseBlock
    });
  }
  drawPoseBlockGraph(graph): void {
    this.itemHeight = 30;
    this.itemMargin = 29;
    var rectsView = graph.append('g').attr('class', 'view');
    var yAxisMapping = {};
    let maxStack = 1;
    graph.each((data) => {

      data.forEach((timelineItem, index) => {
        if (timelineItem.PoseBlock) {
          this.timelinedata = timelineItem.PoseBlock[0];
        }

        var timelines = this.timelinedata;

        timelines.forEach((arrayLoop) => {
          arrayLoop.start_time = new Date(arrayLoop.start_time).getTime();
          arrayLoop.endtime = new Date(arrayLoop.endtime).getTime();
          if (arrayLoop.start_time < this.start) {
            arrayLoop.start_time = this.start;
          }
          if (arrayLoop.endtime > this.end) {
            arrayLoop.endtime = this.end
          }
        })
        // Use the getColor function for PoseBlock color logic
        const getColorForPoseBlock = (d, i) => {
          if (d.posture && (d.posture === 'left')) return 'rgb(251,198,101)'
          else if (d.posture && (d.posture === 'right')) return 'rgb(255, 204, 220)'
          else if (d.posture && (d.posture === 'prone')) return 'rgb(196,164,220)'
          else if (d.posture && (d.posture === 'supine')) return 'rgb(19, 180, 255)'
          else if (d.posture && (d.posture === 'NPP')) return 'rgb(204,204,204)'
          else if (d.posture && (d.posture === 'No Data')) return 'rgb(247, 247, 205)'
          else { }
        };
        yAxisMapping[index] = maxStack;
        maxStack++;
        this.drawTimeRect(rectsView, timelines, yAxisMapping, index, getColorForPoseBlock);
      });

    });
  }
  drawContinueBlockGraph(graph): void {
    this.itemHeight = 12;
    this.itemMargin = 5;
    // Draw ContinueBlock bars at the bottom
    var rectsView = graph.append('g').attr('class', 'view');
    var yAxisMapping = {};
    let maxStack = 1;
    graph.each((data) => {
      data.forEach((timelineItem, index) => {
        if (timelineItem.ContinueBlock) {
          this.timelinedata = timelineItem?.ContinueBlock[0];
          var timelines = this.timelinedata;
          timelines.forEach((arrayLoop) => {
            arrayLoop.start_time = new Date(arrayLoop.start_time).getTime();
            arrayLoop.endtime = new Date(arrayLoop.endtime).getTime();
            if (arrayLoop.start_time < this.start) {
              arrayLoop.start_time = this.start;
            }
            if (arrayLoop.endtime > this.end) {
              arrayLoop.endtime = this.end
            }
            arrayLoop.mid_risk_time = new Date(arrayLoop.mid_risk_time).getTime();
            arrayLoop.high_risk_time = new Date(arrayLoop.high_risk_time).getTime();
            if (arrayLoop.mid_risk_time < this.start) {
              arrayLoop.mid_risk_time = this.start;
            }
            if (arrayLoop.mid_risk_time > this.end) {
              arrayLoop.mid_risk_time = this.end
            }
            if (arrayLoop.high_risk_time < this.start) {
              arrayLoop.high_risk_time = this.start;
            }
            if (arrayLoop.high_risk_time > this.end) {
              arrayLoop.high_risk_time = this.end
            }
          });
          let continueGraphBlock = [];
          timelines.forEach((item) => {
            let endtime: any;
            let time = item.start_time;
            if (item) {
              if (time < this.end) {
                if (item.mid_risk_time) { endtime = item.mid_risk_time }
                else { endtime = item.endtime }
                continueGraphBlock.push({ "start_time": item.start_time, "endtime": endtime, 'color': 'green' });

                if (item.mid_risk_time) {
                  if (item.high_risk_time) { endtime = item.high_risk_time }
                  else { endtime = item.endtime }
                  continueGraphBlock.push({ "start_time": item.mid_risk_time, "endtime": endtime, 'color': 'orange', "mid_risk_overall_time": item.mid_risk_overall_time, "high_risk_time": item.high_risk_time });

                  if (item.high_risk_time) {
                    continueGraphBlock.push({ "start_time": item.high_risk_time, "endtime": item.endtime, 'color': 'red', "mid_risk_overall_time": item.mid_risk_overall_time, "high_risk_time": item.high_risk_time });
                  }
                }
              }
            }
          })
          const getColorForcontinueBlock = (d, i) => {
            if (d.color && (d.color === 'green')) return 'rgb(152, 251, 152)';
            else if (d.color && (d.color === 'orange')) return 'orange'
            else if (d.color && (d.color === 'red')) return 'red';
            else { return 'blue' }
          };
          yAxisMapping[index] = maxStack;
          maxStack++;

          // Adjust styles or data as needed
          this.drawTimeRect(rectsView, continueGraphBlock, yAxisMapping, index, getColorForcontinueBlock)
        }
      });
    });
  }
  drawTimeRect(view, data, yMap, index, getColor): void {

    view
      .selectAll('svg')
      .data(data)
      .enter()
      .append(() => {
        return document.createElementNS(d3Selection.namespaces.svg, 'rect');
      })
      .attr('font-size', 5)
      .attr('x', (d) => {
        return this.getXPos(d, false);
      })
      .attr('y', () => {
        return this.getStackPosition(yMap, index);
      })
      .attr('width', (d, i) => {
        return (d.endtime - d.start_time) * this.scaleFactor;
      })
      .attr('height', this.itemHeight)
      .style('fill', getColor)
      .on('click', (d, i) => {
        var selectedRect = d3Selection.select(this).node();
        var selectorLabel = 'text#' + selectedRect.id + '.textnumbers';
        var selectedLabel = d3Selection.select(selectorLabel).node();
      })
      .attr('class', (d, i) => {
        return 'timelineSeries_' + i;
      })
    view.selectAll("text.bar-text")
      .data(data)
      .enter()
      .append("text")
      .filter(d => (d.color === 'orange' && isNaN(d.high_risk_time)) || (d.color === 'red' && d.high_risk_time !== 'None' && d.mid_risk_overall_time))
      .text(d => {
        let formattedTime = d.mid_risk_overall_time;
        let [hours, minutes] = formattedTime.split(":").map(Number);
        if (hours === 0 && minutes < 60) {
          formattedTime = minutes;
        } else {
          formattedTime = formattedTime;
        }
        return formattedTime;
      })
      .attr('font-size', 5)
      .style('fill', 'white')
      .attr("id", (d, i) => `text_${i}`)
      .attr("x", d => { return this.getRiskPost(d) }  ) // Adjust x position as needed
      .attr("y", d => 68) // Set a fixed value for y or adjust as needed
      .attr("class", "bar-text");
   }

  getXPos(d, isNotes, index?: number, data?: any): number {
    if (isNotes) {
      return this.margin.left + (d.time - this.start) * this.scaleFactor;
    }
    return 20 + (d.start_time - this.start) * this.scaleFactor;
  }

  getStackPosition(yMap, index): number {

    return (this.containerSize.height + this.itemMargin / 2 - this.margin.bottom - (this.itemHeight + this.itemMargin) * yMap[index]);
  }
  getRiskPost(d): number {
    let compareTime = d.mid_risk_overall_time
    let [hours, minutes] = compareTime.split(":").map(Number);
      if (hours === 0 && minutes < 30) {
        // If high_risk_time is less than 30 minutes, return the original position
        return 20 + ((d.start_time - this.start) * this.scaleFactor);
      } else {
        // If high_risk_time is 30 minutes or more, calculate the center position of the red bar
        const barWidth = (d.endtime - d.start_time) * this.scaleFactor;
        return 20 + ((d.start_time - this.start) * this.scaleFactor) + barWidth / 2;
      }
  }
}

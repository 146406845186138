import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { PatientService } from '../../services';
import { AuthService } from '../../services/auth.service';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  hub_id: string;
  hub_description: string;
  pNumber: string;
  pauseMonitoring = false;
  routerStateSnapshot: any;
  pauseDialogRef: any;
  playDialogRef: any;
  hubdialogRef: any;
  riskCount: any;
  statistics_datasource: any;
  showImage: boolean = true;
  subdomain: any;
  userImg: any;
  orgImg: any;
  navigationUrl:any;
  constructor(
    private router: Router,
    public patientService: PatientService,
    public auth: AuthService,
    public dialog: MatDialog,
  ) {
    //this.init()
    this.routerStateSnapshot = this.router.routerState.snapshot;
  }

  ngOnInit(): void {
    this.subdomain = localStorage.getItem('base_org');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.userAdmin();
        this.Organization();
      }
    });
    this.userAdmin();
    this.Organization();
  }

  // init() {
  //   this.pNumber = localStorage.getItem('Ward_Bed Number') || '';
  //   this.hub_id = localStorage.getItem('hub_id') || '';
  // }
  userAdmin() {
    let item = localStorage.getItem('user');
    if (item === 'admin' || item === 'superadmin') {
      this.userImg = 'assets/images/user-admin.png';
    } else {
      this.userImg = null;
    }
  }
  // storageValue() {
  //   this.init()
  //   return true;
  // }

  hasRoute(route: string): boolean {
    return this.router.url.includes(route);
  }

  navigateToConnection(): void {
    // if (this.auth.isTokenValid()) {
    //this.isDropdownOpen = false;
    this.patientService.emitLogoutEvent();
    let dialogRef: any;
    dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        type: 'alert',
        headerImg: 'assets/images/closeIcon.png',
        buttonsAlign: 'horizantal',
        label: `<h1>Do you want to logout?</h1>`,
        yesBtn: 'Confirm',
        cancelBtn: 'Cancel',
      },
    });
    const dialogSubscriber = dialogRef?.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        localStorage.removeItem('checkboxState');
        localStorage.removeItem('visualizeClickedObj');
        localStorage.removeItem('domain');
        localStorage.removeItem('hub_id');
        localStorage.removeItem('hub_description');
        localStorage.removeItem('user');
        localStorage.removeItem('selectedDataFilters');
        localStorage.removeItem('selectedRiskFilters');
        localStorage.removeItem('sortingData');
        localStorage.removeItem('base_org');
        localStorage.removeItem('token');
        localStorage.removeItem('api_url');
        //localStorage.removeItem('refreshtoken');

        this.router.navigateByUrl('');
      }
    });

    // } else {
    //   this.auth.refreshToken();
    // }

  }
  user_role() {
    this.router.navigate(['/admin']);
  }
  Organization() {
    let org = localStorage.getItem('user');
    if (org === 'superadmin') {
      this.orgImg = 'assets/images/organization.png';
    } else {
      this.orgImg = null;
    }
  }
  navigateToOrganization() {
    if (this.auth.isTokenValid()) {
      this.router.navigate(['organization']);
    } else {
      this.auth.refreshToken();
    }
  }
  fileOpen() {
    //window.open("/assets/json/Quick Reference Guide.pdf");
  }
  navigateTODashboard() {
    if (this.auth.isTokenValid) {
      let dialogRef: any;
      dialogRef = this.dialog.open(DialogComponent, {
        width: '430px',
        data: {
          type: 'alert',
          headerImg: 'assets/images/closeIcon.png',
          buttonsAlign: 'horizantal',
          label: `Do you want to cancel 'Add Device' and return to the Dashboard?`,
          yesBtn: 'Yes',
          cancelBtn: 'No',
        },
      });
      const dialogSubscriber = dialogRef?.afterClosed().subscribe((result) => {
        if (result === 'yes') {
          this.updateDashboard();
        }
      });
    } else {
      this.auth.refreshToken();
    }
  }

  navigateDashboard() {
    if (this.auth.isTokenValid) {
      let dialogRef: any;
      dialogRef = this.dialog.open(DialogComponent, {
        width: '430px',
        data: {
          type: 'alert',
          headerImg: 'assets/images/closeIcon.png',
          buttonsAlign: 'horizantal',
          label: `Do you want to leave the Admin screen and return to the Dashboard?`,
          yesBtn: 'Yes',
          cancelBtn: 'No',
        },
      });
      const dialogSubscriber = dialogRef?.afterClosed().subscribe((result) => {
        if (result === 'yes') {
          this.updateDashboard();
          //this.router.navigate([`/${this.subdomain}/dashboard`]);
        }
      });
    } else {
      this.auth.refreshToken();
    }
  }

  updateDashboard(): void {
    this.subdomain = localStorage.getItem('base_org');
    this.navigationUrl = `/${this.subdomain}/dashboard`;
    this.router.navigateByUrl(this.navigationUrl);
  }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urlList } from '../urlConstants';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  private baseUrl = localStorage.getItem('api_url');
  private token = JSON.parse(localStorage.getItem('token'));
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private toaster: ToastrService,
    private auth: AuthService
  ) {
    if (this.token) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token.access_token,
        }),
      };
    }
  }

  post(url, data): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + url, data, this.httpOptions);
  }

  postHttp(url,data):Observable<any>{
    return this.http
    .post<any>(url,data, this.httpOptions);
  }

  postWithCsv(url, data) {
    return this.http.post(this.baseUrl + url, data, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.token.access_token, },),
      responseType: "blob",
    })
  }

  get(url): Observable<any> {
    this.baseUrl = environment.api_url;
    return this.http.get<any>(url,this.httpOptions).pipe(catchError(this.errorHandler));
  }

  getWithToken(url): Observable<any> {
    this.baseUrl = localStorage.getItem('api_url');
    let token = JSON.parse(localStorage.getItem('token'));
    return this.http
      .get<any>(this.baseUrl + '/' + url,{
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + token.access_token, })})
      .pipe(catchError(this.errorHandler));
  }

  getAdmin(url): Observable<any> {
    return this.http
    .get<any>(this.baseUrl + url, this.httpOptions)
    .pipe(catchError(this.errorHandler));
  }

  // (catchError(this.errorHandler));
  patch(url, data): Observable<any> {
    return this.http
      .patch<any>(this.baseUrl + url, JSON.stringify(data), this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  put(url, data): Observable<any> {
    return this.http
      .put<any>(this.baseUrl + url, JSON.stringify(data), this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  putWithoutData(url): Observable<any> {
    return this.http
      .put<any>(this.baseUrl + url, {}, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  delete(url, data?): Observable<any> {
    const header: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    const httpOptions = {
      headers: header,
      body: data,
    };

    return this.http
      .delete<any>(this.baseUrl + url, httpOptions)
      .pipe(catchError(this.errorHandler));
  }
  errorHandler = (error) => {
    console.log(error, "error")
    let errorMessage = '';
    if (error.status === 401 && error.url.indexof('/login') < 0) {
      this.auth.refreshToken();
    } else {
      if (typeof error === 'string') {
        errorMessage = error ? error : 'server error';
      } else {
        errorMessage = `${error.error.message}`;
      }
      // this.toaster.error(errorMessage, '');
    }
    return throwError(errorMessage);
  }
}
